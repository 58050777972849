<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.travels") }}
          <v-spacer />
          <id-button class="mr-2" @click="onCreate">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("hr.travel.request_travel") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          class="elevation-1"
          :headers="table.headers"
          :items="table.data"
          :loading="table.loading"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          single-expand
        >
          <template #item="row">
            <tr>
              <td class="text-center">
                {{ row.item.beginDate | formattedDateTimeMinute }} -
                {{ row.item.endDate | formattedDateTimeMinute }}
              </td>
              <td class="text-center">{{ row.item.startWorkDate | formattedDate }}</td>
              <td class="text-center">
                <v-icon :color="row.item.isDomestic ? 'green' : 'red'">{{
                  row.item.isDomestic ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </td>
              <td class="text-center">
                {{ Number(row.item.attrition || 0).toFixed(2) }}
              </td>
              <td>
                {{ row.item.address }}
              </td>
              <td class="text-no-wrap">
                <template v-if="row.item.project">
                  <v-avatar size="28" :color="row.item.project.color" class="mr-1">
                    <span class="body-2 font-weight-regular white--text ">
                      {{ row.item.project.name.substring(0, 2).toUpperCase() }}
                    </span>
                  </v-avatar>
                  <span>{{ row.item.project.name }}</span>
                </template>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                <request-status-label :status="row.item.requestStatus" />
              </td>
              <td class="text-right">
                <v-btn
                  icon
                  @click.stop.prevent="
                    row.expand(!row.isExpanded);
                    activeTab = 0;
                  "
                >
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card color="grey lighten-3" elevation="0">
                  <v-card-text class="px-4">
                    <v-tabs v-model="activeTab" slider-color="primary" background-color="grey lighten-3">
                      <v-tab href="#expense-timeline">
                        <v-icon color="tertiary" size="18" class="mr-2">tw-hr-expenses</v-icon>
                        {{ $t("hr.travel.expenses") }}
                        <v-btn icon color="primary" class="ml-1" @click="onTabClick(FormTypeEnum.EXPENSE, item)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-tab>
                      <v-tab href="#imprest-timeline">
                        <v-icon color="tertiary" size="18" class="mr-2">tw-hr-cost</v-icon>
                        {{ $t("hr.travel.imprests") }}
                        <v-btn icon color="primary" class="ml-1" @click="onTabClick(FormTypeEnum.IMPREST, item)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-tab>
                      <v-tab href="#subsistence-timeline">
                        <v-icon color="tertiary" size="18" class="mr-2">mdi-layers</v-icon>
                        {{ $t("hr.travel.subsistences") }}
                        <v-btn icon color="primary" class="ml-1" @click="onTabClick(FormTypeEnum.SUBSISTENCE, item)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-tab>
                      <v-tab href="#detail-table">
                        <v-icon color="tertiary" size="18" class="mr-2">mdi-details</v-icon>
                        {{ $t("hr.travel.travel_details") }}
                      </v-tab>
                      <v-tab href="#debit-table">
                        <v-icon color="tertiary" size="18" class="mr-2">mdi-layers</v-icon>
                        {{ $t("hr.travel.debits") }}
                      </v-tab>
                      <v-tab-item value="expense-timeline">
                        <v-card flat>
                          <v-card-text>
                            <travel-expense-timeline :items="item.expenses" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="imprest-timeline">
                        <v-card flat>
                          <v-card-text>
                            <travel-imprest-timeline :items="item.imprests" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="subsistence-timeline">
                        <v-card flat>
                          <v-card-text>
                            <travel-subsistence-timeline :items="item.subsistences" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="detail-table">
                        <v-card flat>
                          <v-card-text class="pa-0">
                            <travel-detail-table :items="item.travelDetails" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="debit-table">
                        <v-card flat>
                          <v-card-text class="pa-0">
                            <travel-debit-table :items="item.debits" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <create-dialog v-model="createDialogStatus" :travel="travel" :form-type="formType" @save="onRefreshData" />
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-06 08:41:34
   * @modify date 2021-01-06 08:41:34
   * @desc Travels
   */
  import { TRAVELS } from "./query";
  import { FormTypeEnum } from "./enums";

  export default {
    name: "Travels",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      TravelExpenseTimeline: () => import("./components/TravelExpenseTimeline"),
      TravelImprestTimeline: () => import("./components/TravelImprestTimeline"),
      TravelSubsistenceTimeline: () => import("./components/TravelSubsistenceTimeline"),
      TravelDebitTable: () => import("./components/TravelDebitTable"),
      TravelDetailTable: () => import("./components/TravelDetailTable"),
      CreateDialog: () => import("./components/CreateDialog")
    },
    data: vm => ({
      activeTab: 0,
      createDialogStatus: false,
      formType: FormTypeEnum.EXPENSE,
      FormTypeEnum,
      travel: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: null
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.travel.travel_dates"),
            align: "center",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.travel.start_work_date"),
            value: "startWorkDate",
            align: "center",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.travel.is_domestic"),
            value: "isDomestic",
            align: "center",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.travel.attrition"),
            value: "attrition",
            align: "center",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("global.address"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.project"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.status"),
            value: "requestStatus",
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "center",
            sortable: false
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employee = this.$store.state.auth.user.id;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onTabClick(formType, travel) {
        this.travel = travel;
        this.formType = formType;
        this.createDialogStatus = true;
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: TRAVELS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateTravels.content || [];
              this.table.totalItems = data.filterWithPaginateTravels.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "travel_create" });
      }
    }
  };
</script>
