export const TravelStatusEnum = {
  OPEN: "OPEN",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  CANCEL: "CANCEL"
};

export const TravelStatusColorEnum = {
  OPEN: "gray",
  APPROVED: "blue",
  REJECTED: "orange",
  COMPLETED: "green",
  CANCEL: "red"
};

export const TravelSpendTypeEnum = {
  HOTEL: "HOTEL",
  FUEL: "FUEL",
  TICKET: "TICKET",
  FOOD: "FOOD",
  OTHER: "OTHER"
};

export const TravelEmployeeTypeEnum = {
  CHIEF: "CHIEF",
  PERSON: "PERSON"
};

export const TravelDetailTypeEnum = {
  TRANSFER: "TRANSFER",
  LAYOVER: "LAYOVER",
  BUSINESS: "BUSINESS"
};

export const TravelDetailSubTypeEnum = {
  [TravelDetailTypeEnum.TRANSFER]: {
    BUS: "BUS",
    PLANE: "PLANE",
    TRAIN: "TRAIN",
    SHIP: "SHIP",
    CAR: "CAR"
  },
  [TravelDetailTypeEnum.LAYOVER]: {
    HOTEL: "HOTEL",
    HOSTEL: "HOSTEL",
    VILLA: "VILLA"
  },
  [TravelDetailTypeEnum.BUSINESS]: {
    FACTORY: "FACTORY",
    OFFICE: "OFFICE"
  }
};

export const FormTypeEnum = {
  EXPENSE: "EXPENSE",
  IMPREST: "IMPREST",
  SUBSISTENCE: "SUBSISTENCE",
  DEBIT: "DEBIT"
};
